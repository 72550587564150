import React, { useContext, useEffect, useState } from 'react'
import CustomButton from '../../../components/CustomButton'
import { BrandContext } from '../../../context/Brand.context'
import { BrandService, RedeemingWayService } from '../../../services'
import localforage from 'localforage'
import { Box, CircularProgress, Tab, Tabs } from '@mui/material'
import { notification } from 'antd'
import Loader from '../../../components/Loader'
import PropTypes from 'prop-types';
import PushNotificationList from '../../communication/subpages/Notification/PushNotificationList'
import Bulk from './Bulk'


function Notifications() {

    const brand = useContext(BrandContext)

    const [state, setState] = useState({
        loader: true,
        generalLoader: false,
        apiError: '',
        brandingLoader: false,
        termConditions :false,
        promoData: {}
    })
    
    const [data, setdata] = useState({
        emailCheck: brand?.emailCheck||false,
        TransactionSmsCheck: brand?.TransactionSmsCheck||false,
        TransactionEmailCheck: brand?.TransactionEmailCheck||false,
        WelcomeSMSCheck: brand?.WelcomeSMSCheck||false,
        WelcomeEmailCheck: brand?.WelcomeEmailCheck||false,
        RedeemEmailCheck: brand?.RedeemEmailCheck||false,
        RedeemSMSCheck: brand?.RedeemSMSCheck||false,
        PointExpireSMSCheck: brand?.PointExpireSMSCheck||false,
        PointExpireEmailCheck: brand?.PointExpireEmailCheck||false,
        InactivityEmailCheck: brand?.InactivityEmailCheck||false,
        InactivitySMSCheck: brand?.InactivitySMSCheck||false,
        promoCheck: false
    })

    useEffect(async() => {
        const query = { brandId: brand._id, delete: false, redeemingWayType: 'promotion' }
        const redeemingWays = await RedeemingWayService.GetQueriedEarningWays({ query })
        console.log(redeemingWays.response.data[0].notification)
        setdata({...data, promoCheck: redeemingWays.response.data[0].notification})
        setState({...state, promoData: redeemingWays.response.data[0], loader: false})
        // redeemingWays.response.data
    }, [])
    


    const handlePublicProfileUpdateFunc = async () => {
        setState({ generalLoader: true, apiError: '' })

        // const user = await localforage.getItem('user')
        // const permissions = await localforage.getItem('permissions')

        const payload = {
            _id: brand._id,
            emailCheck: data.emailCheck,
            TransactionSmsCheck: data.TransactionSmsCheck,
            TransactionEmailCheck: data.TransactionEmailCheck,
            WelcomeEmailCheck: data.WelcomeEmailCheck,
            RedeemEmailCheck: data.RedeemEmailCheck,
            WelcomeSMSCheck: data.WelcomeSMSCheck,
            RedeemSMSCheck: data.RedeemSMSCheck,
            PointExpireSMSCheck: data.PointExpireSMSCheck,
            PointExpireEmailCheck: data.PointExpireEmailCheck,
            InactivitySMSCheck: data.InactivitySMSCheck,
            InactivityEmailCheck: data.InactivityEmailCheck,
        }

        const { error, response } = await BrandService.Update({ toaster: true, payload })
        console.log('error ', error);
        console.log('response', response);

        const updateWays = await RedeemingWayService.Update({ payload: {_id: state?.promoData?._id , notification: data.promoCheck} });
        console.log('updateWays',updateWays);
        
        if (error) return setState({ generalLoader: false, brandingLoader: false, apiError: error.error })

        brand.updateBrand(response.data)
        // return setState({ generalLoader: false, apiError: '' })





        
        
    }


    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  

  return (
    <>
    {
        state.loader?<Loader />:
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label={<div className='tab-text'>SMS / EMAIL</div>} {...a11yProps(0)} />
                <Tab label={<div className='tab-text'>PUSH NOTIFICATIONS</div>} {...a11yProps(1)} />
                <Tab label={<div className='tab-text'>BULK</div>} {...a11yProps(2)} />
            </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <div style={{width: 'fit-content'}}>
                    <div className='d-flex align-items-center space-between mb_8'>
                        <div className='Heading22M color-neutral100'>Notifications</div>
                        <CustomButton btntext={"Update"} icon={state.generalLoader && <CircularProgress color='inherit' size={14} />} onClick={async()=>{
                            setState({ generalLoader: true, apiError: '' })
                            await handlePublicProfileUpdateFunc();
                            setState({ generalLoader: false, apiError: '' })
                        }} />
                    </div>
                    <div className='bar mt_10 mb_20'></div>
                    <input type="checkbox" checked={data.emailCheck} onChange={(e)=>{
                        if(e.target.checked === false){
                            setdata({...data, emailCheck: e.target.checked, promoCheck: e.target.checked})
                        }
                        else{
                            setdata({...data, emailCheck: e.target.checked})
                        }
                        }} />
                    <label className='notiText5 ml_10' for="vehicle1"> Turn on Email/SMS notifications</label>
                    <div className='bar mt_10 mb_20'></div>
                    <div className='row notiText1'>
                        <div className='col-5'>NAME</div>
                        <div className='col-3 col-end'>EMAIL</div>
                        <div className='col-4 col-center'>SMS</div>
                    </div>
                    <div className='bar mt_10 mb_20'></div>
                    <div className='notiText2'>Transactional Notification</div>
                    <div className='bar mt_10 mb_20'></div>
                    <div className='row'>
                        <div className='col-5'>
                            <div className='notiText4'>Welcome</div>
                            <div className='notiText3'>Notify customers when they sign up for loyalty; this email/SMS is triggered upon loyalty signup.</div>
                        </div>
                        <div className='col-3 col-end'>
                            <input 
                            // className='disabled' 
                            checked={data.WelcomeEmailCheck} 
                            onChange={(e)=>{if(data.emailCheck===true)setdata({...data, WelcomeEmailCheck: e.target.checked})}}
                            type="checkbox"
                            />
                            </div>
                        <div className='col-4 col-center'>
                            <input 
                            checked={data.WelcomeSMSCheck} 
                            onChange={(e)=>{if(data.emailCheck===true)setdata({...data, WelcomeSMSCheck: e.target.checked})}}
                            // className='disabled'
                            
                            type="checkbox"/></div>
                    </div>
                    <div className='bar mt_10 mb_20'></div>
                    <div className='row'>
                        <div className='col-5'>
                            <div className='notiText4'>Points Update</div>
                            <div className='notiText3'>Notify customers when their points balance updates; this email/SMS is triggered upon each transaction.</div>
                        </div>
                        <div className='col-3 col-end'>
                            <input 
                            
                            checked={data.TransactionEmailCheck}
                            onChange={(e)=>{if(data.emailCheck===true)setdata({...data, TransactionEmailCheck: e.target.checked})}}
                            type="checkbox"/></div>
                        <div className='col-4 col-center'>
                        <input type="checkbox" 
                        checked={data.TransactionSmsCheck}
                         onChange={(e)=>{
                                if(data.emailCheck===true)setdata({...data, TransactionSmsCheck: e.target.checked})}
                                } />
                            </div>
                    </div>
                    <div className='bar mt_10 mb_20'></div>
                    <div className='row'>
                        <div className='col-5'>
                            <div className='notiText4'>Redeem</div>
                            <div className='notiText3'>Notify customers when they redeem points for rewards or discounts.</div>
                        </div>
                        <div className='col-3 col-end'>
                            <input 
                            // className='disabled' 
                            checked={data.RedeemEmailCheck}
                            onChange={(e)=>{if(data.emailCheck===true)setdata({...data, RedeemEmailCheck: e.target.checked})}}
                            type="checkbox"/>
                            </div>
                        <div className='col-4 col-center'>
                            <input 
                            // className='disabled' 
                            
                            checked={data.RedeemSMSCheck}
                            onChange={(e)=>{if(data.emailCheck===true)setdata({...data, RedeemSMSCheck: e.target.checked})}}
                            type="checkbox"/></div>
                    </div>
                    <div className='bar mt_10 mb_20'></div>
                    <div className='row'>
                        <div className='col-5'>
                            <div className='notiText4'>Point Expire</div>
                            <div className='notiText3'>Notify customers when their loyalty points are close to expiring, encouraging them to redeem.</div>
                        </div>
                        <div className='col-3 col-end'>
                            <input 
                            checked={data.PointExpireEmailCheck}
                            onChange={(e)=>{if(data.emailCheck===true)setdata({...data, PointExpireEmailCheck: e.target.checked})}}
                             type="checkbox"/></div>
                        <div className='col-4 col-center'><input checked={data.PointExpireSMSCheck}
                            onChange={(e)=>{if(data.emailCheck===true)setdata({...data, PointExpireSMSCheck: e.target.checked})}} type="checkbox"/></div>
                    </div>
                    <div className='bar mt_10 mb_20'></div>
                    <div className='row'>
                        <div className='col-5'>
                            <div className='notiText4'>Inactivity</div>
                            <div className='notiText3'>Notify customers when they’ve been inactive for a while, inviting them back to engage with your brand.</div>
                        </div>
                        <div className='col-3 col-end'>
                            <input className='disabled' 
                            
                            checked={data.InactivityEmailCheck}
                            onChange={(e)=>{if(data.emailCheck===true)setdata({...data, InactivityEmailCheck: e.target.checked})}} type="checkbox"/></div>
                        <div className='col-4 col-center'><input checked={data.InactivitySMSCheck}
                            onChange={(e)=>{if(data.emailCheck===true)setdata({...data, InactivitySMSCheck: e.target.checked})}} type="checkbox"/></div>
                    </div>
                    <div className='bar mt_10 mb_20'></div>
                    <div className='notiText2'>Scheduled Notification</div>
                    <div className='bar mt_10 mb_20'></div>
                    <div className='row'>
                        <div className='col-5'>
                            <div className='notiText4'>Promotion</div>
                            <div className='notiText3'>Notify customers when there’s a promotional offer or special deal available.</div>
                        </div>
                        <div className='col-3 col-end'>
                            {/* <input className='' checked={data.emailCheck} type="checkbox"/> */}
                            <input type="checkbox" checked={data.promoCheck} onChange={(e)=>{
                                if(data.emailCheck===true)setdata({...data, promoCheck: e.target.checked})}
                                } />
                            </div>
                        <div className='col-4 col-center'><input className='disabled' checked={false} type="checkbox"/></div>
                    </div>
                    <div className='bar mt_10 mb_20'></div>
                    <div className='row'>
                        <div className='col-5'>
                            <div className='notiText4'>Referral</div>
                            <div className='notiText3'>Notify customers when they can earn rewards for referring friends to your brand.</div>
                        </div>
                        <div className='col-3 col-end'><input className='disabled' checked={data.emailCheck} type="checkbox"/></div>
                        <div className='col-4 col-center'><input className='disabled' checked={false} type="checkbox"/></div>
                    </div>
                    <div className='bar mt_10 mb_20'></div>
                    <div className='row'>
                        <div className='col-5'>
                            <div className='notiText4'>Birthday Followup</div>
                            <div className='notiText3'>Notify customers when it’s time to celebrate their birthday with a follow-up message.</div>
                        </div>
                        <div className='col-3 col-end'><input className='disabled' checked={data.emailCheck} type="checkbox"/></div>
                        <div className='col-4 col-center'><input className='disabled' checked={false} type="checkbox"/></div>
                    </div>
                    <div className='bar mt_10 mb_20'></div>
                    <div className='row'>
                        <div className='col-5'>
                            <div className='notiText4'>Birthday Promotion</div>
                            <div className='notiText3'>Notify customers when a special birthday promotion or reward is available to them.</div>
                        </div>
                        <div className='col-3 col-end'><input className='disabled' checked={data.emailCheck} type="checkbox"/></div>
                        <div className='col-4 col-center'><input className='disabled' checked={false} type="checkbox"/></div>
                    </div>
                    <div className='bar mt_10 mb_20'></div>

                </div>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <PushNotificationList />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <Bulk />
            </CustomTabPanel>
        </Box>
    }
    </>
  )
}

export default Notifications



function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }