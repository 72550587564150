import { Card, CardActionArea, CardContent, CardMedia, CircularProgress } from '@mui/material';
import { Typography } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import { PngIcons } from '../../../icons';
import { FlowService } from '../../../services';
import localforage from 'localforage';
import { BrandContext } from '../../../context/Brand.context';
import CustomButton from '../../../components/CustomButton';

function Bulk() {
    const [filteredData, setFilteredData] = useState();
    const [loader, setLoader] = useState(true);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const brand = useContext(BrandContext)
    const [state, setState] = useState({
        loader: true,
        generalLoader: false,
        apiError: '',
        brandingLoader: false,
        termConditions :false,
        promoData: {}
    })

    const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    function getDayName(number) {
        if (number >= 1 && number <= 7) {
            return dayNames[number % 7]; // Using modulo operator to handle numbers greater than 7
        } else {
            return "Invalid day number"; // Handling invalid numbers
        }
    }

    const onLoad = async () => {
        let flow = await localforage.getItem('flow');
        console.log('mskingcommunivstion', flow);
    
        if ((!flow) || flow.length === 0) {
          const { response, error } = await FlowService.Get({ query: { brandId: brand._id, delete: false, flowType: 'new' } })
          console.log('loyalty Xoop Jeep', response, error);
    
          if (response) {
            localforage.setItem('flow', response.data);
            flow = response.data
          }
        }
    
        let tempFlow = []
    
        console.log('flow',flow);
        
    
        if(flow[0].flow.length>0){
          for (const value of flow[0].flow) {
            if(value.active!==true&&value.active!==false) {
              // value.active===true
              tempFlow.push({...value, active: true})
            }
            else{
              tempFlow.push(value)
            }
          }
        }
    
    console.log('tempFlow',tempFlow);
    
    flow[0].flow=tempFlow
    
    console.log('floww',flow);
    
        setFilteredData(flow)
        setLoader(false)
      }
    
      useEffect(onLoad, [])

  return (
    <div>
        <div className='d-flex align-items-center space-between mb_8'>
            <div className='Heading22M color-neutral100'>Bulk Notifications</div>
            <CustomButton btntext={"Send"} icon={state.generalLoader && <CircularProgress color='inherit' size={14} />} onClick={async()=>{
                // setState({ generalLoader: true, apiError: '' })
                // await handlePublicProfileUpdateFunc();
                // setState({ generalLoader: false, apiError: '' })
            }} />
        </div>
        <div className='bar mt_10 mb_20'></div>
        <div style={{ 
              display: 'grid', 
              gridTemplateColumns: 'repeat(auto-fill, minmax(400px, 1fr))', 
              gap: '16px' 
            }}>
                {filteredData && filteredData.length > 0 && 
                filteredData.map((element, index) => (
                  element?.flow?.length > 0 && element.flow.map((ele, ind) => (

                    
                    
                    
                    (ele.state[0].festival==='Custom Date' ||
                    ele.state[0].festival==='Week') &&
                    
                    <Card 
                    style={{
                        backgroundColor: ind === selectedIndex && '#F2F6FF',
                        border: ind === selectedIndex && '2px solid #2960EC'
                    }}
                    onClick={()=>{
                        setSelectedIndex(ind)
                    }}
                    key={`${index}-${ind}`} sx={{ flex: '1 1 300px', height: 'fit-content' }} >
                    {console.log('1',index)}
                    {console.log('2',ind)}
                    <CardActionArea style={{ alignItems: 'flex-start', display: 'flex' }}>
                      <CardContent style={{width: '100%'}} sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                      <div className="d-flex gap-6">
                          <Typography gutterBottom style={{fontSize: '15px', color: '#141519', fontWeight: 500, fontFamily: 'Inter'}} variant="h5" component="div" sx={{fontWeight:'bold'}}>
                          {
                            ele.state[0].key === "referrallogin"? //receiver
                            "Referral Receiver":
                            ele.state[0].key === "referral"? //sender
                            "Referral Sender":
                            ele.state[0].key === "customerInactivity"? 
                            "Inactivity":
                            ele.state[0].key === "point"? 
                            "Loyalty Reward":
                            (ele.state[0].key === "event" && ele.state[0].festival === "Birth Day" ) ? 
                            "Birthday":
                            (ele.state[0].key === "event" && (ele.state[0].festival === "Custom Date"||ele.state[0].festival === "Week") && ((ele.state1[0]?.key === "rewardDeal" || ele.state1[0]?.key === "addTag" || ele.state1[0]?.key === "bonusPoints" )) ) ? 
                            "Promotion":
                            (
                              ele.state[0].key === "visit" 
                              // ||
                              // (ele.state[0].key === "event" && (ele.state[0].festival === "Custom Date"||ele.state[0].festival === "Week") && ((ele.state1[0]?.key === "rewardDeal" || ele.state1[0]?.key === "addTag")) )
                            ) ? 
                            "Visit-Based Reward":
                          ele.block || element.name || element.key}
                        </Typography>
                          {/* {ele?.active!==false&&<img src={PngIcons.tick} width={16} height={16}/>} */}
                          {<img src={ele.active?PngIcons.tick:PngIcons.tickG} width={16} height={16}/>}
                        </div>
                        <Typography style={{fontSize: '13px', color: '#2E3039', fontWeight: 400, fontFamily: 'Inter'}} color="text.secondary" sx={{
                          overflow: 'hidden',
                          display: '-webkit-box', fontFamily: 'Inter',
                          WebkitBoxOrient: 'vertical',
                          WebkitLineClamp: 2,
                          textOverflow: 'ellipsis',
                        }}>
                          {
                            ele.state[0].key === "referrallogin"? //receiver
                              "Share the love, earn the points":
                            ele.state[0].key === "referral"? //sender
                              "Share the love, earn the points":
                            ele.state[0].key === "customerInactivity"? 
                              "Re-engage dormant customers with enticing":
                              ele.state[0].key === "point"? "Loyalty Point Reward: "+ele.state1[ele.state1.findIndex(item => item.key === "rewardDeal")]?.dealname+" for "+ele.state[0].points+" points" :
                            ele.state[0].key === "visit"? 
                              "Visit-Based Coupon: "+ele.state1[ele.state1.findIndex(item => item.key === "rewardDeal")]?.dealname+" after "+ele.state[0].visits+" visits":
                            (ele.state[0].key === "event" && ele.state[0].festival === "Birth Day" ) ? 
                              "Delight customers with special points on their special day":
                            (ele.state[0].key === "event" && (ele.state[0].festival === "Custom Date"||ele.state[0].festival === "Week") && ((ele.state1[0]?.key === "bonusPoints")) ) ? 
                              ele.state[0].festival === "Week"? "Weekly Coupon: "+ele.state1[ele.state1.findIndex(item => item.key === "bonusPoints")].bonuspoints[ele.state1[ele.state1.findIndex(item => item.key === "bonusPoints")].bonuspoints.length-1]+"x Points on every "+getDayName(parseInt(ele.state[0].week)):
                              ele.state[0].festival === "Custom Date"?
                            'Date-Specific Coupon: '+ele.state1[ele.state1.findIndex(item => item.key === "bonusPoints")].bonuspoints[ele.state1[ele.state1.findIndex(item => item.key === "bonusPoints")].bonuspoints.length-1]+'x Points from '+new Date(ele.state[0].eventStart).toLocaleDateString()+' to '+new Date(ele.state[0].eventEnd).toLocaleDateString():''
                            : 
                            (ele.state[0].key === "event" && (ele.state[0].festival === "Custom Date"||ele.state[0].festival === "Week") && (!(ele.state1[0]?.key === "rewardDeal" || ele.state1[0]?.key === "addTag")) ) ? 
                            "Boost loyalty with multiplied points on selected dates":
                            (ele.state[0].key === "event" && (ele.state[0].festival === "Custom Date"||ele.state[0].festival === "Week") && ((ele.state1[0]?.key === "rewardDeal" || ele.state1[0]?.key === "addTag")) ) ? 
                            ele.state[0].festival === "Week"?
                            "Weekly Coupon: "+ele.state1[ele.state1.findIndex(item => item.key === "rewardDeal")]?.dealname+" on every "+getDayName(parseInt(ele.state[0].week)):
                            ele.state[0].festival === "Custom Date"?
                            'Date-Specific Coupon: '+ele.state1[ele.state1.findIndex(item => item.key === "rewardDeal")]?.dealname+' from '+new Date(ele.state[0].eventStart).toLocaleDateString()+' to '+new Date(ele.state[0].eventEnd).toLocaleDateString():'':
                          'desc'}
                        </Typography>
                      </CardContent>
                      <CardMedia
                        component="img"
                        height="140"
                        style={{ padding: 12, width: '140px' }}
                        image={
                          ele.state[0].key === "referrallogin"? //receiver
                            PngIcons.NewRBlock:
                            ele.state[0].key === "referral"? //sender
                            PngIcons.NewRBlock:
                            ele.state[0].key === "customerInactivity"? 
                            PngIcons.NewIABlock:
                            (ele.state[0].key === "event" && ele.state[0].festival === "Birth Day" ) ? 
                            PngIcons.newBD:
                            (ele.state[0].key === "visit"||(ele.state[0].key === "event" && (ele.state[0].festival === "Custom Date"||ele.state[0].festival === "Week"))) ? 
                            PngIcons.newCouponBlock:
                            ele.state[0].key === "point"? 
                            PngIcons.newRewardBlock:
                          'https://letsenhance.io/static/8f5e523ee6b2479e26ecc91b9c25261e/1015f/MainAfter.jpg'}
                        alt={ele.block || element.name || element.key}
                      />
                    </CardActionArea>
                  </Card>
                  
                ))))
              }
            </div>
    </div>
  )
}

export default Bulk